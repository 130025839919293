import { Avatar, type ComboboxItem, Group, Text } from '@mantine/core';
import { IconKey, IconUser, IconUserExclamation } from '@tabler/icons-react';
import { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { clerkClientUtils } from '~/core/auth/clerk.utils';
import { useMemberships } from '~/ui/hooks/organization-memberships.hook';
import { useUserInfo } from '~/ui/hooks/user-info.hook';
import { FormSelect } from '../Select/Select';
import { FormSelectProps } from '../Select/types';
import { type IBaseFormInputProps } from '../shared/FormInputTypes';

export const User: React.FC<{ userId: string; avatarOnly?: boolean }> = ({ userId, avatarOnly }) => {
  const { name, imageUrl, kind } = useUserInfo(userId);

  const { avatar, displayName } = useMemo(() => {
    if (kind === 'USER')
      return { avatar: <Avatar src={imageUrl} name={name} size={'xs'} />, displayName: name };
    if (kind === 'API_TOKEN') return { avatar: <IconKey size={'1em'} />, displayName: name };
    return {
      avatar: <IconUserExclamation size={'1rem'} />,
      displayName: (
        <Text fz={'sm'} truncate="end">
          {name}
        </Text>
      ),
    };
  }, [imageUrl, kind, name]);

  return (
    <Group gap={'xs'} maw={150} wrap="nowrap">
      {avatar}
      {displayName}
    </Group>
  );
};

export const FormUserSelect: React.FC<IBaseFormInputProps & Omit<FormSelectProps, 'data'>> = ({
  name,
  ...props
}) => {
  const { t } = useTranslation('fieldsComponent');
  const memberships = useMemberships();
  const data =
    memberships?.data?.map((member) => ({
      label: clerkClientUtils.getFullName(member),
      value: member.publicUserData.userId!,
      avatar: member.publicUserData.imageUrl,
    })) ?? [];

  return (
    <FormSelect
      loading={memberships?.isLoading}
      placeholder={t('Select user...')}
      name={name}
      icon={<IconUser size="1rem" />}
      itemComponent={Person}
      {...props}
      data={data}
    />
  );
};

export type PersonSelectItem = ComboboxItem & { avatar?: string | null };

export const Person = forwardRef<HTMLDivElement, PersonSelectItem>(
  ({ label, value, avatar, ...others }: PersonSelectItem, ref) => {
    return (
      <Group ref={ref} {...others} gap={'xs'}>
        <Avatar src={avatar} name={label} size={'xs'} />
        {label}
      </Group>
    );
  },
);

Person.displayName = 'Person';
