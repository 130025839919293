import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

type UseMultiSelectSearchProps<T> = {
  name: string;
  onChange?: (values: string[]) => void;
  onCreate?: (item: string) => string | false;
  onFailCreation?: () => void;
  setData: Dispatch<SetStateAction<T[]>>;
};

export const useMultiSelectSearch = <T extends { label: string; value: string }>({
  name,
  onCreate,
  onFailCreation,
  onChange,
  setData,
}: UseMultiSelectSearchProps<T>) => {
  const { getValues } = useFormContext<Record<string, string[]>>();
  const [search, setSearch] = useState('');

  const handleValueSelect = useCallback(
    (val: string) => {
      setSearch('');
      const currentValues: string[] = getValues(name) ?? [];

      if (val === '$create') {
        const created = onCreate ? onCreate(search) : search;
        if (!created) {
          onFailCreation?.();
          return;
        }

        const createdOption = { label: created, value: created } as T;

        setData((current) => [...current, createdOption]);
        onChange?.([...currentValues, created]);
      } else {
        const updatedValues = currentValues.includes(val)
          ? currentValues.filter((v) => v !== val)
          : [...currentValues, val];

        onChange?.(updatedValues);
      }
    },
    [search, onCreate, onFailCreation, onChange, setData, name],
  );

  return {
    search,
    setSearch,
    handleValueSelect,
  };
};
